.dashboard-mainframe {
    display: flex;
    gap: 1rem;
}

.dashboard-card {
    width: 20rem;
    height: 20rem;
    border-radius: .25rem;
    padding: .5rem;
    border: 1px solid #cbcbcb;
}

.dashboard-card {
    display: flex;
    flex-direction: column;
}

.dashboard-card .title{
    font-weight: bold;
    gap: .5rem;
    display:flex
}
.dashboard-card .data{
    display: flex;
    justify-content: space-between;
}